.section-banner {
  position: relative;
  height: 455px;
  padding: 48px 0;
  @include breakpoint-down(md) {
    padding: 24px 0;
    height: auto;
  }
  .section-content {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 32px 0;
  }
  .section-wrap {
    height: 100%;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
  .title {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    margin-bottom: 35px;
  }
  .desc {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    @include text-normal;
    margin-bottom: 24px;
  }
}
.section-banner-right {
  height: 455px;
  position: relative;
  padding: 48px 0;
  @include breakpoint-down(md) {
    padding: 24px 0;
    height: auto;
  }
  &::before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    @include stretch;
    z-index: 1;
  }
  .title {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .desc {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    @include text-normal;
    font-weight: 400;
    margin-bottom: 24px;
    max-width: 665px;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    left: 40%;
    z-index: -1;
  }
  .section-content {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 32px 0;
    position: relative;
    z-index: 2;
  }
  .section-wrap {
    height: 100%;
  }
}
