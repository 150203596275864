.section-hightlights {
  @include breakpoint-down(md) {
    padding-bottom: 32px;
  }
  .row {
    margin-bottom: -60px;
    @include breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  .item-list {
    margin-bottom: 60px;
    font-family: "Poppins";
    @include breakpoint-down(md) {
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .item-sum {
    @include title-3 ;
    color: $brand-secondary;
    font-weight: 500;
    span {
      @include title-4;
      font-weight: normal;
    }
  }
  .item-desc {
    color: $brand-primary;
    @include title-4;
    font-weight: 700;
  }
}
