.section-carousel-controls {
  &.align-y-center {
    position: absolute;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    height: 0;
    width: auto;
    top: 50%;
    right: 0;
    left: 0;
    padding: 0;
    margin: 0 -30px;
    @include breakpoint-down(md) {
      margin: 0;
    }
    [class^="btn-carousel-"] {
      transform: translateY(-50%);
    }
  }
  &.align-x-right {
    display: flex;
    justify-content: flex-end;
    [class^="btn-carousel-"] {
      width: 24px;
      height: 24px;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  div[class^="btn-carousel-"] {
    cursor: pointer;
    background: transparent;
    border: none;
    color: #000;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    transition: background .4s ease, border-color .4s ease;
    // &.swiper-button-disabled {
    //   display: none;
    // }
  }
}
.swiper-pagination {
  position: static;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #dddddd;
  padding: 2px;
  &-bullet {
    border-radius: 8px;
    background-color: #dddddd;
    width: 37px;
    height: 9px;
    margin: 0 !important;
    &-active {
      background-color: $brand-secondary;
    }
  }
}
