.section-carousel {
  .section-content {
    position: relative;
  }
  .swiper-slide {
    height: auto;
  }
  .item-carousel {
    padding: 32px 13px 26px;
    text-align: center;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #f6f6f6;
    height: 100%;
  }
  .item-media {
    width: 176px;
    height: 176px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto 24px;
  }
  .img {
    @include stretch;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  .item-title {
    font-family: 'Poppins';
    @include title-4;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .item-meta {
    color: $brand-secondary;
    @include text-small;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  .section-carousel-controls {
    margin: 0 12px;
    [class^="btn-carousel-"] {
      font-size: 20px;
    }
    @include breakpoint-up(md) {
      margin: 0 -32px;
      // display: none;
    }
  }
}

.swiper-pagination-controls {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
