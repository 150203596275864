$btn-font-size: $font-size-base !default;
$btn-line-height: $font-size-base !default;
$btn-box-shadow: inset 0 3px 5px rgba(black,.125);
$btn-disabled-opacity: .65;
$btn-border-width: 1px !default;
// *************************************************************************

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: $btn-border-width solid transparent;
  border-radius: 8px;
	white-space: nowrap;
	font-size: $btn-font-size;
	line-height: $btn-line-height;
	user-select: none;
	transition-property: box-shadow, background-color;
	transition-duration: .2s;
	outline: none !important;
	text-decoration: none !important;
	padding: 6px 12px;

	&:active,
	&.active {
		box-shadow: $btn-box-shadow;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
		box-shadow: none;
	}
  &.btn-primary {
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.44px;
    padding: 12px 16px;
    color: #fff;
    position: relative;
    z-index: 1;
    background-color: #00a0dc;
    // box-shadow: 0 3px 5px rgba(0, 160, 220, 0.3);
    // &::before {
    //   content: '';
    //   @include stretch;
    //   right: 50%;
    //   border-radius: 4px;
    //   background-color: #00a0dc;
    //   z-index: -1;
    //   box-shadow: 0 3px 5px rgba(0, 160, 220, 0.3);
    //   transition: right .6s linear, left .6s linear;
    // }
    // &:hover {
    //   &::before {
    //     right: 0;
    //   }
    // }
    &-small {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.444444px;
      background-color: #00a0dc;
      // box-shadow: 0 3px 5px rgba(0, 160, 220, 0.3);
      color: #fff;
      padding: 8px 16px;
    }
  }
  &.btn-secondary {
    background-color: #b3dde3;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    padding: 10px 29px 8px;
    color: #000;
  }
  &.btn-hollow {
    @include text-small;
    font-weight: 600;
    color: #fff;
    border: 1px solid #fff;
    padding: 9px 24px;
  }
  &.btn-black {
    background: #000;
    color: #fff;
    padding: 13px 38px;
    font-size: 16px;
    font-weight: 700;
  }
}

a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
