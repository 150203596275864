.promo-block-slider {
  margin-bottom: 60px;
  padding-bottom: ratio(460px, 1280px);
  position: relative;
  @include breakpoint-down(md) {
    padding-bottom: ratio(400px, 375px);
    margin-bottom: 48px;
  }
  .swiper-container {
    @include stretch;
    // height: 455px;
    // @include breakpoint-down(md) {
    //   height: 400px;
    // }
  }
  .img {
    @include stretch;
    z-index: -1;
    background-position: center;
    background-size: cover;
  }
  .media {
    &::before {
      content: '';
      background: rgba(#000000, .3);
      @include stretch;
    }
  }
  .item-carousel,
  .container {
    height: 100%;
  }
  .btn-primary {
    color: #fff;
  }
  p {
    &:first-child {
      margin-bottom: 0;
    }
  }
  .item-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    // padding-top: 77px;
    // padding-bottom: 144px;
    // padding-left: 50%;
    padding-top: 108px;
    color: #fff;
    position: relative;
    z-index: 2;
    @include breakpoint-up(md) {
      max-width: 480px;
    }
    @include breakpoint-down(md) {
      justify-content: center;
      padding-left: 0;
      padding-top: 100px;
      padding-bottom: 54px;
    }
    .title {
      // @include title-3;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 48px */
      @include breakpoint-down(md) {
        font-size: 24px;
        line-height: 36px;
      }
      margin-bottom: 12px;
    }
    .desc {
      margin-bottom: 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 16.8px */
      @include breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }
  }
  .swiper-pagination-controls {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    display: block;
    padding-top: 0;
    // background: rgba(#000000, .5);
    z-index: 10;
    @include breakpoint-down(md) {
      display: none;
    }
  }
  .section-carousel-controls {
    position: absolute;
    bottom: 12px;
    z-index: 2;
    left: 12px;
    [class^="btn-carousel-"] {
      color: #fff;
      font-size: 24px;
    }
    @include breakpoint-up(md) {
      display: none;
    }
  }
  .swiper-pagination {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    text-align: left;
    align-items: initial;
    justify-content: initial;
    width: 100%;
  }
  .swiper-pagination-bullet {
    width: 100%;
    height: auto;
    background: transparent;
    border-radius: initial;
    color: #fff;
    opacity: 1;
    padding: 8px;
    background: #00A0DC;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    border-right: 1px solid #fff;
    &:first-child {
      border-radius: 12px 0px 0px 12px;
    }
    &:last-child {
      border-right: none;
      border-radius: 0px 12px 12px 0px;
    }
    &:focus { outline: none; }
    &:hover {
      background: #F1C40F;
      color: #000;
    }
    .title {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 21.6px */
      white-space: nowrap;
      // margin-bottom: 9px;
    }
    .desc {
      display: none;
      @include text-caption;
    }
    &-active {
      color: #000;
      background: #F1C40F;
      // display: none;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
