.section {
  margin-bottom: 60px;
  @include breakpoint-down(md) {
    margin-bottom: 48px;
  }
  &.margin-b-0 {
    margin-bottom: 0;
    @include breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .section-head {
    text-align: center;
    // margin-bottom: 44px;
    & + .section-content {
      margin-top: 44px;
      &-sm {
        margin-top: 32px;
        .row {
          margin-bottom: -24px;
        }
      }
      @include breakpoint-down(md) {
        margin-top: 24px;
      }
    }
    &.head-flex {
      display: flex;
      align-items: baseline;
    }
  }
  .head-page-link {
    font-family: 'Poppins';
    font-weight: 600;
    margin-left: 40px;
  }
  .section-title {
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    &.color-secondary {
      color: $brand-secondary;
    }
    & + .section-desc {
      padding-top: 32px;
      @include breakpoint-down(md) {
        padding-top: 0;
      }
    }
  }
  .section-desc-large {
    padding-top: 32px;
    @include title-4;
    font-weight: 700;
    @include breakpoint-down(md) {
      padding-top: 0;
    }
  }
  ul {
    list-style: none;
    padding-left: 16px;
    li {
      position: relative;
      line-height: 32px;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        background: #00b9ff;
        left: -14px;
        top: 14px;
        border-radius: 50%;
        position: absolute;
      }
    }
  }
}

.section-work {
  .item-work {
    @include col-custom(2.4);
    @include breakpoint-down(md) {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  .section-desc-sub {
    padding-top: 40px;
  }
  .item-work-wrap {
    border-radius: 4px;
    background-color: #b3dde3;
    padding: 20px;
    height: 100%;
    .item-media {
     margin-bottom: 24px;
    }
    .item-desc {
      font-weight: 600;
    }
  }
}
.section-common {
  [class^="col-lg-"] {
    &:first-child {
      @include breakpoint-down(md) {
        order: 1;
      }
    }
    &:last-child {
      @include breakpoint-down(md) {
        order: 0;
      }
    }
  }
  &-switch {
    [class^="col-lg-"] {
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 0;
      }
    }
  }
  .title {
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    margin-bottom: 21px;
  }
  .desc {
    @include text-normal;
    margin-bottom: 34px;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }
  .media {
    border-radius: 16px;
    img {
      border-radius: inherit;
    }
    @include breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
}

.section-video {
  background: $brand-secondary;
  padding: 48px 0;
  @include breakpoint-down(md) {
    padding: 24px 0;
  }
  .section-title {
    @include title-3;
    font-weight: 700;
    text-align: center;
  }
}
.block-video {
  position: relative;
  padding-bottom: ratio(466px, 830px);
  iframe {
		@include stretch;
		width: 100%;
		height: 100%;
	}
}

.section-check-Service {
  text-align: center;
  padding-top: 0;
  .section-content {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #f6f6f6;
    padding: 32px 16px;
  }
  img {
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    margin-bottom: 21px;
  }
  .btn {
    margin-top: 12px;
  }
}
.section-faq {
  padding: 56px 0;
  margin-bottom: 0;
  .section-head {
    margin-bottom: 16px;
  }
  .section-title {
    @include title-3;
  }
}
.affix-anchor {
  position: relative;
  z-index: -1;
  transform: translateY(-100px);
  @include breakpoint-down(md) {
    transform: translateY(-56px);
  }
}
.section-link {
  padding: 0 0 64px;
  position: relative;
  margin-bottom: 0;
  .nav.affix, /* the fixed position and a width are required */
  .nav.affix-bottom { position: fixed !important; width: 100%; }
  .nav.affix {
    top: 100px;
    @include breakpoint-down(md) {
      top: 56px;
    }
   }  /* affixedTop position */
  .nav.affix-bottom { top: auto; bottom: 100px; }  /* affixedBottom position */
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    right: 0;
    background: $brand-primary;
    height: 64px;
  }
  ul {
    padding-left: 0;
    display: flex;
    justify-content: center;
    @include breakpoint-down(md) {
      overflow-x: auto;
      justify-content: initial;
    }
    li {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      font-weight: bold;
      &:not(:last-child) {
        margin-right: 12px;
      }
      &::before {
        display: none;
      }
    }
    a {
      color: #fff;
      white-space: nowrap;
      &:hover {
        color: $brand-secondary;
      }
    }
  }
}

.bg-type-1 {
  background: rgba($color: #b3dde3, $alpha: .2);
}
.section-404 {
  padding-top: 190px;
  margin-bottom: 100px;
  @include breakpoint-down(md) {
    padding-top: 120px;
    margin-bottom: 32px;
  }
  .section-desc {
    @include title-3;
  }
}
.section-img {
  @include breakpoint-down(md) {
    [class^="col-lg-"] {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  img {
    border-radius: 16px;
  }
}
ul.custom-list {
  text-align: center;
  p {
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    @extend .icon-checked;
    &::before {
      font-family: 'iconfont';
      background-color: transparent;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      font-size: 16px;
      color: $brand-primary;
      right: calc(100% + 8px);
      top: 4px;
    }
  }
  li {
    color: #000;
    font-size: 14px;
    &::before {
      display: none;
    }
    span,
    a {
      text-decoration: underline;
      color: #000;
    }
  }
}
