.section-history-carousel {
  background: $blue-2;
  padding: 48px 0;
  @include breakpoint-down(md) {
    padding: 24px 0;
  }
  .swiper-wrapper {
    margin-bottom: 26px;
  }
  .item-date {
    color: $brand-primary;
    @include title-4;
    font-weight: 700;
    font-family: 'Poppins';
    margin-bottom: 8px;
  }
  .item-desc {
    @include text-small;
  }
  .item-slide {
    padding-top: 16px;
    position: relative;
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      background: $brand-secondary;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
    }
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 2px;
      left: 2px;
      background: $brand-primary;
      border-radius: 50%;
    }
  }
  .section-content {
    position: relative;
    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      background: #fff;
      top: 6px;
      left: -38px;
      transform: translateY(-50%);
    }
    &::after {
      left: calc(100% - 38px);
    }
    // &-wrap {
    //   @extend .icon-chevron-right;
    //   &::before {
    //     font-family: 'iconfont';
    //     position: absolute;
    //     font-size: 14px;
    //     top: 6px;
    //     right: calc(100% + 8px);
    //     width: 24px;
    //     height: 24px;
    //     color: #fff;
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: center;
    //     transform: translateY(-50%);
    //   }
    // }
  }
}
