
// .cookie-bar {
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 50;
//   transition: transform .4s ease;
//   transform: translateY(100%);
//   &.is--visible {
//       transform: translateY(0);
//   }
//   .container {
//       position: relative;
//   }
//   &-in {
//       background: #fff;
//       padding: 20px;
//       box-shadow: 0px -2px 13px 1px rgba(0,0,0,0.13);
//       border-radius: 5px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       max-width: 512px;
//       margin-left: auto;
//   }
//   .content-block {
//       & > p {
//           margin-bottom: 0;
//       }
//       a {
//           border-bottom: 1px dashed #000;
//           &:hover {
//               border-color: $brand-primary;
//           }
//       }
//   }
//   .bar-right {
//       margin-left: 16px;
//       .btn {
//           &:not(:last-child) {
//               margin-bottom: 16px;
//           }
//       }
//   }
// }
// @include breakpoint-down(sm) {
//   .cookie-bar {
//       &-in {
//           background: #fff;
//           padding: 20px;
//           box-shadow: 0px -2px 13px 1px rgba(0,0,0,0.13);
//           border-radius: 5px;
//           display: block;
//       }
//       .content-block {
//           margin-bottom: 24px;
//       }
//       .bar-right {
//           margin-left: 0;
//           .btn {
//               width: auto;
//               &:not(:last-child) {
//                   margin-bottom: 0;
//                   margin-right: 16px;
//               }
//           }
//       }
//   }
// }

.cookie-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(29, 23, 55, 0.25);
  transition: opacity 1s ease;
  padding-bottom: 20px;
  @include breakpoint-down(sm) {
    padding-bottom: 35px;
  }
  .container {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1000px;
    width: 100vw;
  }
  &-in {
      box-shadow: 0px -2px 13px 1px rgba(0,0,0,0.13);
      border-radius: 20px;
      background-color: #FFF;
      padding: 50px 50px 40px 50px;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 100vh;
  }
  .cookie-external {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    max-height: 100vh;
    width: auto;
    margin: 0 auto;
    min-height: auto;
    transition: all ease;
    transition-delay: 1s;
    transition-duration: 0.8s;
    opacity: 1;
    height: 154px;
    @include breakpoint-down(sm) {
      height: 318px;
      flex-wrap: wrap;
    }
  }
  .cookie-external.not-active {
    transition-delay: 0s !important;
    height: 0 !important;
    opacity: 0 !important;
  }
  .bar-left {
    margin-right: 80px;
    @include breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: -20px;
    }
  }
  .content-block {
    p {
      margin-bottom: 0;
    }
      a {
        border-bottom: 1px  dashed #000;
          &:hover {
            color: #facf1a;
            border-bottom: 1px dashed #facf1a;
          }
      }
  }
  .content-block-text {
    display: inline-flex;
    width: 100%;
    .content-title-images-wrap {
      max-width: 20px;
      width: 100%;
      margin-top: 7px;
      margin-right: 8px;
      align-items: center;
      @include breakpoint-down(sm) {
        margin-top: 0;
      }
      .coockie-images-container {
        position: relative;
        padding-bottom: 100%;
        .coockie-images {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
        }
      }
    }
    .coockie-title {
      @include title-4;
      display: block;
      font-weight: 600;
      margin-bottom: 10px;
      @include breakpoint-down(sm) {
        @include text-large;
      }
    }
  }
  .style-block-text-coockie {
    max-width: 520px;
    width: 520px;
    margin-bottom: 24px;
    ul {
      padding-left: 20px;
      list-style-type: disc;
      a {
        color: #4bb9aa;
      }
    }
    p {
      ul {
        list-style-type: disc;
        a {
          color: #4bb9aa;
        }
      }
    }
    p {
      a {
        color: #4bb9aa;
      }
    }
    a {
      color: #4bb9aa;
    }
    p {
      max-width: 800px;
    }
    @include breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .coockie-link {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    @include breakpoint-down(sm) {
      display: inline-flex;
      margin-bottom: 14px;
    }
    li {
      list-style-type: none;
      margin-right: 40px;
      @include breakpoint-down(sm) {
        margin-right: 13px;
      }
      a {
        font-weight: 600;
        border-bottom: 2px solid transparent;
        &:hover {
          border-color: #00b9ff;
        }
        @include breakpoint-down(sm) {
          font-size: 10px;
        }
      }
    }
    li:last-child {
      margin-right: 0;
    }
  }
  .coockie-btn-wrap {
    .coockie-btn {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: .444444px;
      text-transform: uppercase !important;
      padding: 8px 12px;
      text-transform: none;
      max-width: 248px !important;
      width: 100% !important;
      margin-bottom: 20px !important;
      @include breakpoint-down(sm) {
        @include text-small;
        margin-bottom: 14px !important;
        max-width: 100% !important;
      }
    }
    .btn-accept {
      @include text-small;
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: #00b9ff;
      }
      @include breakpoint-down(sm) {
        margin-bottom: 14px;
      }
      .btn-accept-only {
        @include text-normal;
        @include breakpoint-down(sm) {
          @include text-small;
        }
      }
    }
    .btn-individual {
      @include text-normal;
      display: inline-flex;
      align-items: center;
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: #00b9ff;
      }
      @include breakpoint-down(sm) {
        @include text-small;
      }
    }
    .btn-individual.not-active {
      z-index: -1 !important;
      opacity: 0 !important;
    }
    .btn-icon {
      @include text-normal;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
      .icon {
        font-size: 10px;
      }
    }
  }
  .coockie-inside {
    display: block !important;
    .coockie-essential-inside-wrap {
      overflow-x: hidden;
      overflow-y: hidden;
      max-height: 900px;
      padding-right: 10px;
      padding-left: 10px;
      transition: all ease;
      transition-delay: 0s;
      transition-duration: 1s;
      opacity: 0;
      height: 0;
      .content-block {
        margin-bottom: 20px;
      }
      .coockie-btn-wrap {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .individual-style {
          max-width: 80px;
          width: 100%;
          margin-bottom: 20px !important;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: .444444px;
          text-transform: uppercase;
          padding: 8px 12px;
        }
      }
    }
    .coockie-essential-inside-wrap.active {
      transition-delay: 1s !important;
      height: 140px !important;
      opacity: 1 !important;
      @include breakpoint-down(sm) {
        height: 170px !important;
      }

    }
    .coockie-individual-inside-wrap {
      overflow-x: hidden;
      max-height: 900px;
      padding-left: 10px;
      padding-right: 10px;
      transition: all ease;
      transition-delay: 0s;
      transition-duration: 1s;
      opacity: 0;
      height: 0;

      .coockie-wrap {
        margin-bottom: 40px;
      }
      .coockie-title {
        @include title-4;
        font-weight: 600;
        display: block;
        margin-bottom: 10px;
      }
      .coockie-btn-wrap {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .btn-accept-all {
          max-width: 248px;
          width: 100%;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: .444444px;
          text-transform: uppercase;
          padding: 8px 12px;
          font-weight: 500;
          color: #FBFBFB;
          background-color: black;
        }
        .btn-save {
          max-width: 248px;
          width: 100%;
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: .444444px;
          text-transform: uppercase;
          padding: 8px 12px;
          border-radius: 8px !important;
          background-color: #00a0dc;
        }
        .btn-back  {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          max-width: 45px;
          margin-bottom: 20px;
          border-bottom: 2px solid transparent;
          &:hover {
            border-color: #00b9ff;
          }
          .btn-back-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            .icon {
              font-size: 10px;
            }
          }
        }
        .btn-accept-only {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          max-width: 205px;
          border-bottom: 2px solid transparent;
          &:hover {
            border-color: #00b9ff;
          }
          .btn-accept-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            font-size: 10px;
          }
        }
      }
      .coockie-information {
        .coockie-info-wrap {
          margin-bottom: 20px;
        }
        .coockie-info-wrap:last-child {
          margin-bottom: 0;
        }
        .style-block-text-coockie {
          max-width: 100%;
          width: 100%;
          margin-bottom: 16px;
          .coockie-block-title {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .coockie-title {
              @include text-large;
              font-weight: 600;
            }
          }
        }
        .coockie-info {
          color: #00b9ff;
          border-bottom: 2px solid transparent;
          &:hover{
            border-color: #00b9ff;
          }
          .coockie-title {
            display: inline-flex;
            align-items: center;
          }
          .coockie-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            .icon {
              font-size: 10px;
            }
          }
        }
        .coockie-hide {
          color: #00b9ff;
          border-bottom: 2px solid transparent;
          &:hover {
            border-color: #00b9ff;
          }
          .coockie-hide-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
            .icon {
              font-size: 10px;
            }
            &:hover {
              color: #facf1a;
            }
          }
        }
        .coockie-accardion {
          margin-top: 16px;
          margin-bottom: 16px;
          transition: all;
          transition-duration: 0.7s;
          max-height: 0;
          overflow: hidden;
          position: relative;
          .coockie-accardion-wrap {
            margin-top: 16px;
            .coockie-text-wrap {
              @include text-caption;
              display: flex;
              flex-wrap: wrap;
              margin-left: -10px;
              margin-right: -10px;
              align-items: center;
              text-align: left;
              font-weight: 600;
              @include breakpoint-down(sm) {
                font-size: 10px;
              }
              .coockie-text-left {
                padding: 12px 10px 12px 10px;
                width: 33.333333%;
              }
              .coockie-text-right {
                padding: 12px 10px 12px 10px;
                width: 66.666667%;
              }
            }
          }
        }
      }
      .coockie-individual-link {
        margin-top: 20px;
        .coockie-link {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          li {
            margin-right: 10px;
            a {
              @include text-small;
              font-weight: 600;
              border-bottom: 2px ssolid transparent;
              &:hover {
                border-color: #00b9ff;
              }
              @include breakpoint-down(sm) {
                font-size: 10px;
              }
            }
          }
          li:last-child {
            margin-right: 0px;
          }
        }
      }
    }
    .coockie-individual-inside-wrap.active {
      transition-delay: 1s !important;
      height: 70vh !important;
      opacity: 1 !important;
    }
  }
  .cookie-scroll {
      &::-webkit-scrollbar-button {
        background-image: none;
        background-repeat:no-repeat;
        width:6px;
        height:0px
        }

        &::-webkit-scrollbar-track {
        background-color:#000;
        box-shadow:0px 0px 3px #000 inset;
        border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background-color:#00a0dc;
        box-shadow:0px 1px 1px #00a0dc inset;
        background-image: none;
        background-position:center;
        background-repeat:no-repeat;
        }

        &::-webkit-resizer{
        background-image: none;
        background-repeat:no-repeat;
        width:7px;
        height:0px
        }

        &::-webkit-scrollbar{
        width: 11px;
        }
        overflow-y: auto;
        scrollbar-color: #00a0dc #000;
        scrollbar-width: thin;
  }
}
@include breakpoint-down(sm) {
  .cookie-bar {
      &-in {
          box-shadow: 0px -2px 13px 1px rgba(0,0,0,0.13);
          border-radius: 20px;
          background-color: #FFF;
          padding: 28px 20px 28px 20px;
      }
      .content-block {
        p {
          @include text-small;
          margin-bottom: 14px;
        }
      }
  }
}
