.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #344754;
  z-index: 12;
  transition: background .4s ease-in-out;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12);
  &.not-scrolled {
    box-shadow: none;
    background-color: rgba($color: #344754, $alpha: .9);
    &.sidebar-opened {
      background-color: #344754;
    }
  }
  &-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @include breakpoint-down(md) {
      align-items: center;
      padding: 12px 0;
    }
  }
  .logo {
    margin-right: 60px;
    display: block;
    // img {
    //   height: 50px;
    // }
    @include breakpoint-down(md) {
      img {
        height: 32px;
      }
    }
  }
  .search-block {
    position: relative;
    padding-left: 57px;
    border-radius: 4px;
    background: transparent;
    height: 48px;
    @include breakpoint-down(md) {
      background: rgba(#fff, .1);
      width: 100%;
      margin-bottom: 24px;
    }
    .btn-icon-search {
      font-size: 24px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 57px;
      height: 48px;
      display: inline-flex;
      justify-content: center;
      .icon {
        pointer-events: none;
      }
    }
    .btn-search-clear {
      font-size: 16px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: 48px;
      height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      display: none;
      @include breakpoint-down(md) {
        display: inline-flex;
      }
      .icon {
        pointer-events: none;
      }
      &:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 4px;
        bottom: 4px;
        width: 1px;
        background: #dad9d9;
      }
    }
    input {
      width: calc(100% + 57px);
      margin-left: -57px;
      padding: 6px 50px 6px 57px;
      height: 48px;
      background: transparent;
      box-shadow: none;
      border: none;
      display: none;
      @include breakpoint-down(md) {
        display: block;
      }
    }
  }
  .search-block-opened {
    .search-block {
      background: rgba(#fff, .1);
      width: 100%;
      input {
        display: block;
      }
      .btn-search-clear {
        display: inline-flex;
      }
    }
  }
  &-sidebar {
    width: 100%;
    @include breakpoint-down(md) {
      position: fixed;
      top: 56px;
      right: 0;
      max-width: 375px;
      background-color:#344754;
      padding: 32px 32px 48px;
      height: calc(100vh - 56px);
      transition: transform .4s ease;
      transform: translateX(100%);
      display: flex;
    }
  }
  &.sidebar-opened {
    .header-sidebar {
      transform: translateX(0);
    }
  }
  &-sidebar-wrap {
    display: flex;
    justify-content: space-between;
    @include breakpoint-down(md) {
     flex-direction: column;
     align-items: initial;
     width: 100%;
     height: 100%;
     padding: 24px 0 50px;
    }
    .sidebar-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include breakpoint-down(md) {
       flex-direction: column;
       align-items: initial;
      //  order: 1;
      }
    }
    .sidebar-footer {
      display: none;
      @include breakpoint-down(md) {
        display: block;
      }
    }
  }
  .close-sidebar {
    opacity: 0.5;
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    span {
      pointer-events: none;
      width: 22px;
      height: 22px;
      margin-left: 8px;
      margin-right: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid #989898;
      color: #989898;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        left: 50%;
        content: ' ';
        height: 12px;
        width: 2px;
        background-color: #989898;
      }
      &::before {
        transform: translate(-50%,0%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%,0%) rotate(-45deg);
      }
    }
  }
  .btns-group {
    display: flex;
    height: 100%;
    margin-left: 8px;
    @include breakpoint-down(md) {
      margin-left: 0;
      justify-content: center;
      margin-bottom: 24px;
    }
    .btn {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
.burger--icon {
  @include burger(18px, 2px, 3px, #fff);
  pointer-events: none;
}
.btn--burger {
  width: 40px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
  @include breakpoint-down(md) {
    display: inline-flex;
  }
  .sidebar-opened & {
    .burger--icon {
      @include burger-to-cross;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
  }
  & + .btn-primary-small {
    @include breakpoint-down(md) {
      display: none;
    }
  }
}
.sidebar {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  background: $brand-primary;
  z-index: 11;
  @include breakpoint-down(md) {
    top: 56px;
  }
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include breakpoint-down(md) {
      overflow-x: auto;
      justify-content: initial;
    }
  }
  li {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-weight: bold;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  a {
    color: #fff;
    white-space: nowrap;
    &:hover {
      color: $brand-secondary;
    }
  }
}
