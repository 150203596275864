.section-terminals {
  .item-title {
    font-family: 'Poppins';
    @include title-4;
    font-weight: 700;
    margin-bottom: 13px;
  }
  .item-terminals {
    @include breakpoint-down(md) {
      margin-bottom: 32px;
    }
  }
  .item-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    & + .btn {
      margin-top: 32px;
    }
  }
  .media {
    margin-bottom: 32px;
    &-vertical {
      img {
        max-height: 560px;
      }
    }
    img {
      border-radius: 16px;
    }
  }
}
