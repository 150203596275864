.section-column {
  .item-column {
    text-align: center;
    margin-bottom: 24px;
    // @include breakpoint-down(md) {
    //   margin-bottom: 48px;
    // }
    &-wrap {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      height: 100%;
    }
  }
  .item-media {
    margin-bottom: 24px;
    min-height: 48px;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
    }
    img {
      max-height: 48px;
    }
  }
  .item-title {
    font-family: "Poppins";
    @include title-4;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .item-desc {
    margin-bottom: 24px;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
  }
}
