// переменные проекта

$font-family-base: "Open Sans", Helvetica, Arial, sans-serif !default;

$path-fonts:    "../fonts"; // путь к директории со шрифтами

$font-size-base: 14px !default;
$line-height-base: 1.4 !default;

$footer-height: 100px;

// переменные Цвета
// *************************************************************************
$blue: #00b9ff;
$blue-2: #cfe5f8;
$blue-3: #00a0dc;
$green: green;
$orange: orange;
$red: red;
$pink: pink;
$cyan: #4bb9aa;
$purple: purple;
$yellow: #facf1a;
$gray: gray;

$brand-primary: $blue;
$brand-secondary: $blue-3;

// --------------------------------------------------
$body-bg: white !default;
$text-color: black !default;

$link-color: $brand-primary !default;
$link-hover-color: $brand-secondary !default;

// --------------------------------------------------
$input-border: #ccc !default;
$input-bg:  transparent !default;
$input-bg-disabled: $gray !default;
$input-color: black !default;
$input-border-focus: $blue !default;
$input-color-placeholder: $gray !default;

@function t($template){
  @return #{'[data-template="#{$template}"]'};
}

$template-main: t("home");
$template-main: t("contacts");
