.promo-carousel {
  margin-bottom: 60px;
  // background-color: $brand-secondary;
  padding-bottom: ratio(455px, 1425px);
  position: relative;
  @include breakpoint-down(md) {
    padding-bottom: ratio(400px, 375px);
    margin-bottom: 48px;
  }
  .swiper-container {
    @include stretch;
    // height: 455px;
    // @include breakpoint-down(md) {
    //   height: 400px;
    // }
  }
  .media {
    @include breakpoint-down(md) {
      padding-bottom: ratio(561px, 842px);
      position: relative;
      width: 100%;
    }
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: 1;
    left: 44%;
    top: 174px;
    bottom: 65px;
    max-width: 930px;
    @include breakpoint-down(md) {
      left: 0;
      top: 0;
      bottom: 0;
      max-width: 100%;
    }
    // &::before {
    //   content: '';
    //   @include stretch;
    //   background-color: inherit;
    //   z-index: -1;
    // }
  }
  .item-carousel {
    height: 100%;
    display: flex;
    align-items: center;
    @include breakpoint-down(md) {
      display: block;
    }
  }
  .item-content {
    max-width: 436px;
    position: relative;
    z-index: 2;
    width: 40%;
    @include breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 24px;
      padding-top: 100px;
    }
  }
  .title {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 18px;
    @include breakpoint-down(md) {
      font-size: 32px;
      line-height: 45px;
    }
  }
  .swiper-pagination-controls {
    position: absolute;
    bottom: 24px;
    right: 0;
    left: 0;
  }
  .swiper-pagination {
    background-color: #000;
    padding: 2px;
    &-bullet {
      background-color: #000;
      &-active {
        background-color: #fff;
      }
    }
  }
}
