.section-partners-carousel {
  .swiper-slide {
    height: auto;
  }
  .media {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
