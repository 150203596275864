.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	outline: 0;
  .close {
    top: 22px;
    right: 22px;
    position: absolute;
    z-index: 1;
    color: $gray;
    font-size: 20px;
    @include breakpoint-down(sm) {
      top: 16px;
      right: 8px;
    }
  }
  &-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    max-width: 1056px;
    width: 100%;
    padding: 24px;
  }
}
.modal-open {
	overflow: hidden;
}
.fade {
	opacity: 0;
	transition: opacity .15s linear;
}
.modal.fade .modal-dialog {
	transition: transform .3s ease-out;
	transform: translate(0,-25%);
}

.modal.in .modal-dialog {
	transform: translate(0,0);
}
.modal-dialog {
	position: relative;
	width: auto;
	display: flex;
  align-items: center;
  justify-content: center;
	height: 100%;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.in {
	opacity: .5;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}
.modal-show {
  .item-title {
    margin-bottom: 16px;
    @include title-2;
  }
  .close {
    position: static;
    color: #000;
  }
  .item-desc {
    @include breakpoint-down(md) {
      @include text-small;
    }
  }
}
