.nav-main {
  width: 100%;
  .footer & {
    width: auto;
  }
  @include breakpoint-down(md) {
    order: 1;
    margin-right: 0;
    .header & {
      margin-bottom: 60px;
      // text-align: right;
    }
    li {
      display: block;
    }
  }
  text-transform: uppercase;
  & > ul {
    display: flex;
    // justify-content: space-between;
    width: 100%;
    // padding-top: 13px;
    @include breakpoint-down(md) {
      display: block;
    }
    & > li {
      // padding: 0 12px;
      @include breakpoint-down(md) {
        padding-right: 32px;
        &:not(:last-child) {
          margin-bottom: 24px;
          .footer & {
            margin-bottom: 16px;
          }
        }
      }
      &:not(:last-child) {
        margin-right: 16px;
        .footer & {
          margin-right: 32px;
        }
        @include breakpoint-down(md) {
          margin-right: 0;
        }
      }
      li {
        @include breakpoint-down(md) {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .search-block-opened  & {
    display: none;
    @include breakpoint-down(md) {
      display: block;
    }
  }
  ul {
    margin-bottom: 0;
  }
  a {
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    border-bottom: 2px solid transparent;
    .footer & {
      text-transform: initial;
      font-size: 16px;
    }
    &:hover {
      border-color: $brand-primary;
      @include breakpoint-down(md) {
        border-color: transparent;
      }
    }
    @include breakpoint-down(md) {
      white-space: initial;
    }
  }
  .active {
    & > a {
      border-color: $brand-primary;
      // @include breakpoint-down(md) {
      //   border-color: transparent;
      //   color: $brand-primary;
      // }
    }
  }
  .dropdown {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 38px;
      pointer-events: none;
      @include breakpoint-down(md) {
        display: none;
      }
      .footer & {
        display: none;
      }
    }
    &.is--open {
      & > a {
        &::before {
          transform: translate(0%,-50%) rotate(90deg);
        }
      }
      ul {
        @include breakpoint-down(md) {
          position: relative;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    & > a {
      @extend .icon-chevron-left;
      margin-right: 30px;
      position: relative;
      @include breakpoint-down(md) {
        pointer-events: none;
      }
      &.dropdown-toggle {
        @include breakpoint-down(md) {
          pointer-events: none;
        }
      }
      .footer & {
        margin-right: 0;
      }
      &::before {
        font-family: 'iconfont';
        position: absolute;
        color: #fff;
        right: -29px;
        top: 50%;
        transform: translate(0%,-50%) rotate(-90deg);
        font-size: 11px;
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include breakpoint-down(md) {
          right: -32px;
        }
        .footer & {
          display: none;
        }
      }
    }
    ul {
      position: absolute;
      top: calc(100% + 31px);
      visibility: hidden;
      padding: 0;
      width: 0;
      list-style: none;
      opacity: 0;
      transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
      .footer & {
        position: static;
        display: block;
        width: auto;
        padding-top: 9px;
        visibility: visible;
        opacity: 1;
      }
      &::before {
        content: '';
        position: absolute;
        width: 200vw;
        height: 100%;
        left: -100vw;
        right: -100vw;
        background-color: rgba(27, 27, 27, 0.8);
        z-index: -1;
        @include breakpoint-down(md) {
          display: none;
        }
        .footer & {
          display: none;
        }
      }
      @include breakpoint-down(md) {
        transition: opacity .4s ease-in-out, visibility .0s step-end;
        // position: static;
        padding: 8px 0 0;
        width: 100%;
        // opacity: 1;
        // visibility: visible;
      }
      a {
        @include text-small;
        font-size: 12px;
        white-space: nowrap;
        font-weight: 400;
        line-height: 32px;
        opacity: 0.8;
        font-family: 'Poppins';
        text-transform: initial;
        .footer & {
          line-height: 24px;
          @include breakpoint-down(md) {
            line-height: 16px;
          }
        }
      }
      li {
        border-bottom: none;
        margin-right: 0;
        &:first-child {
          margin-top: 16px;
          @include breakpoint-down(md) {
            margin-top: 0;
          }
          .footer & {
            margin-top: 0;
          }
        }
        &:last-child {
          margin-bottom: 26px;
          @include breakpoint-down(md) {
            margin-bottom: 0;
          }
          .footer & {
            margin-bottom: 0;
          }
        }
      }
    }
    &:hover {
      @include breakpoint-up(lg) {
        &::after {
          pointer-events: initial;
        }
        & > a {
          &::before {
            transform: translate(0%,-50%) rotate(90deg);
          }
        }
        ul {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .nav-link-desc {
    max-width: 205px;
    padding-top: 15px;
    p {
      opacity: 0.8;
      color: #ffffff;
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
