.section-managing-team {
  .managing-team-list {
    .row {
      justify-content: space-between;
    }
    // .item-desc {
    //   @include text-normal;
    // }
    .item-title {
      font-family: 'Poppins';
      @include title-3;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
  .btn {
    margin-top: 16px;
  }
  .column-left {
    @include col-custom(4);
    @include breakpoint-down(md) {
      width: 100%;
    }
  }
  .column-right {
    @include col-custom(7);
    @include breakpoint-down(md) {
      width: 100%;
    }
  }
  .item-managing-team {
    .media {
      max-width: 376px;
      position: relative;
      border-radius: 16px;
      @include breakpoint-down(md) {
        margin-bottom: 24px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 8px;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: inherit;
        border-radius: 16px;
      }
    }
    img {
      border-radius: 16px;
    }
    &:not(:last-child) {
      margin-bottom: 88px;
      @include breakpoint-down(md) {
        margin-bottom: 64px;
      }
    }
    &:last-child {
      margin-bottom: 24px;
    }
    &:nth-child(odd) {
      .media {
        &::before {
          left: 8px;
        }
      }
    }
    &:nth-child(even) {
      // text-align: right;
      .column-left {
        order: 1;
        @include breakpoint-down(md) {
          order: 0;
        }
      }
      .media {
        &::before {
          right: 8px;
          @include breakpoint-down(md) {
            right: initial;
            left: 8px;
          }
        }
      }
    }
  }
  &.section-common-desc {
    .column-left {
      @include col-custom(6);
      @include breakpoint-down(md) {
        width: 100%;
      }
    }
    .column-right {
      @include col-custom(6);
      @include breakpoint-down(md) {
        width: 100%;
      }
    }
    .item-title {
      font-family: 'Poppins';
      @include title-3;
      font-weight: 700;
      margin-bottom: 21px;
    }
    // .item-desc {
    //   @include text-small;
    // }
    .media {
      max-width: calc(100% - 24px);
      @include breakpoint-down(md) {
        max-width: 100%;
      }
    }

  .item-managing-team {
    &:nth-child(even) {
      // text-align: right;
      .column-left {
        margin-left: auto;
        .media {
          margin-left: auto;
        }
      }
    }
    }
  }
}
