.search {
  padding-top: 156px;
  margin-bottom: 100px;
  @include breakpoint-down(md) {
    padding-top: 124px;
    margin-bottom: 32px;
  }
  .search-title {
    @include title-3;
    margin-bottom: 8px;
  }
  .found-results {
    margin-bottom: 40px;
  }
  .item-list {
    @include text-normal;
    font-weight: 600;
    padding: 18px 0;
    border-bottom: 1px solid $brand-primary;
    &:first-child {
      border-top: 1px solid $brand-primary;
    }
    a {
      color: #000;
    }
    mark {
      color: $brand-primary;
      background: transparent;
    }
  }
}

.search-block-main {
  position: relative;
  margin-bottom: 16px;
  border-bottom: 1px solid $brand-primary;
  .icon {
    position: absolute;
    top: 50%;
    left: 16px;
    font-size: 24px;
    transform: translateY(-50%);
    color: $brand-primary;
  }
  .search-block-main-btn {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $brand-primary;
    padding: 0;
  }
  input {
    border: none;
    height: 56px;
    padding: 16px 70px 16px 48px;
    border-radius: 4px;
    background: #fff;
    display: block;
    width: 100%;
    color: $brand-primary;
    &::placeholder {
      color: $brand-primary;
    }
    &:focus {
      &::placeholder {
        color: rgba($brand-primary,.5);
      }
    }
  }
}
