.footer {
  background-color: #344754;
  padding: 24px 0 36px;
  @include breakpoint-down(md) {
    padding: 24px 0 8px;
  }
  [class^="col-lg-"] {
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .logo {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 200;
    color: #fff;
    margin-bottom: 16px;
    display: block;
    max-width: 200px;
    span {
      color: $yellow;
      font-weight: 700;
    }
  }
  &-sub {
    margin-bottom: 56px;
    @include breakpoint-down(md) {
      margin-bottom: 24px;
      position: relative;
      padding-bottom: 46px;
      .col-lg-4 {
        position: static;
      }
    }
    &-media {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &-left {
      display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // height: 100%;
      color: #fff;
      padding-top: 10px;
      @include breakpoint-down(md) {
        display: block;
      }
      .footer-list {
        margin-left: 32px;
        @include breakpoint-down(md) {
          margin-left: 0px;
          margin-top: 24px;
        }
      }
    }
    &-right {
      color: #fff;
    }
  }
  &-sup {
    .row {
      align-items: flex-end;
    }
    .footer-list {
      display: flex;
      flex-wrap: wrap;
      li {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
  .copyright {
    p {
      opacity: 0.25;
      color: #ffffff;
      font-family: 'Poppins';
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-title {
    font-weight: 600;
    font-family: 'Poppins';
    color: #ffffff;
    margin-bottom: 16px;
  }
  .item {
    .footer-list {
      padding-top: 12px;
    }
  }
  .footer-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    text-transform: initial;
    // & + .footer-list-link {
    //   padding-top: 16px;
    // }
    li {
      font-family: 'Poppins';
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      opacity: .8;
      // &:not(:last-child) {
      //   margin-bottom: 8px;
      // }
    }
    a {
      opacity: 0.5;
      color: #ffffff;
    }
    p {
      &:last-child {
        margin-bottom: 24px;
      }
    }
    &-link {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      text-transform: initial;
      li {
        opacity: 0.8;
        @include text-caption;
        color: #ffffff;
        // &:not(:last-child) {
        //   margin-bottom: 8px;
        // }
      }
      a {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 12px;
        opacity: .8;
        .icon {
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}
.social-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include breakpoint-down(md) {
    position: absolute;
    left: 0px;
    bottom: 0;
  }
  a {
    color: #00B9FF;
    width: 40px;
    height: 40px;
    border: 2px solid #00B9FF;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 32px;
      @include breakpoint-down(md) {
        margin-right: 16px;
      }
    }
  }
}
