.my-col-5-3 {
  @include col-custom(4.1);
}
.my-col-3-9 {
  @include offset-custom(1.2);
  @include col-custom(3.9);
}
.my-col-2-8 {
  @include col-custom(2.8);
}
// [class^="page--"] {
//   margin-bottom: 48px;
//   @include breakpoint-down(md) {
//     margin-bottom: 32px;
//   }
// }
.panel-group {
  .collapse {
    display: none;
  }
  .in {
     display: block;
  }
  .collapsing {
     position: relative;
     height: 0;
     overflow: hidden;
     transition: height .35s ease;
  }
}
.panel-common {
  .panel-default {
    border: 1px solid #C9DAF4;
    border-radius: 6px;
    padding: 17px 24px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .is-open {
    background: #b3dde3;
    border-color: transparent;
    .panel-title {
      a {
        color: #fff;
      }
    }
  }
  .panel-body {
    padding-top: 24px;
  }
  .panel-title {
    position: relative;
    @include text-normal;
    padding-right: 24px;
    font-weight: 600;
    .collapsed {
      span {
        transform: rotate(0deg);
      }
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(180deg);
      transition: transform .4s ease-in-out;
      font-size: 24px;
    }
  }
}
.page-title {
  @include title-3;
  font-weight: 600;
  margin-bottom: 48px;
}
.page-link {
  list-style: none;
  padding-left: 0;
  margin-bottom: 48px;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 48px;
    }
    a {
      color: $brand-secondary;
      @include title-3;
    }
  }
  .active {
    a {
      color: $brand-primary;
    }
  }
}
.padding-top-header {
  padding-top: 110px;
  @include breakpoint-down(md) {
    padding-top: 74px;
  }
}
[x-cloak] {
  display: none !important;
}
