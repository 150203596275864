.section-form {
  .section-title {
    @include title-3;
    font-weight: 700;
  }
  [class^="col-lg-"] {
    margin: 0 auto;
  }
  .section-head {
    max-width: 456px;
    margin: 0 auto;
  }
  .section-title-extra {
    @include title-3;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .section-wrap {
    & > .row {
      justify-content: center;
    }
  }
}
.section-form-bg {
  position: relative;
  [class^="col-lg-"] {
    margin: 0 auto;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
  .section-head {
    max-width: 456px;
    margin: 0 auto;
  }
  .section-wrap {
    position: relative;
    z-index: 2;
    background: #F6F6F6;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 24px 16px 40px;
    @include breakpoint-down(md) {
      text-align: left;
    }
    & > .row {
      justify-content: center;
    }
  }
  .section-title {
    @include title-3;
    font-weight: 700;
    text-align: center;
  }
  .section-description {
    a {
      font-weight: 700;
      text-decoration: underline;
      color: #000;
    }
  }
  .title {
    font-family: 'Poppins';
    @include title-4;
    font-weight: 700;
    margin-bottom: 13px;
  }
  .checkbox  {
    span{
      &:before {
        border: 1px solid #b6b8bb;;
      }
      .icon {
        color: $brand-secondary;
      }
    }
    input:checked + span {
      &:before {
        background: transparent;
      }
      .icon {
        opacity: 1;
      }
    }
  }

}
