.section-description {
  &-bg {
    background-color: $yellow;
    padding: 124px 0 102px;
    @include breakpoint-down(md) {
      padding: 74px 0 52px;
    }
    .section-head {
      margin-bottom: 0;
    }
    .section-title {
      font-family: 'Poppins';
      @include title-3;
      font-weight: 700;
    }
  }
  .btn {
    margin-top: 16px;
  }
}
