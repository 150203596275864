.item-pos {
  margin-bottom: 24px;
  text-align: center;
  @include breakpoint-down(md) {
    margin-bottom: 0px;
    &:not(:last-child) {
      margin-bottom: 40px;

    }
  }
  &-wrap {
    &:hover {
      .item-media {
        &::before {
          background-color: $brand-primary;
        }
      }
    }
  }
  img {
    width: 246px;
    height: 246px;
    margin: 0 auto;
    @include breakpoint-down(md) {
      width: 320px;
      height: 320px;
    }
  }
  .item-content {
    margin-bottom: 24px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .item-media {
    width: 246px;
    height: 246px;
    margin: 0 auto 18px;
    position: relative;
    display: block;
    @include breakpoint-down(md) {
      margin-bottom: 8px;
      width: 320px;
      height: 320px;
    }
    // &::before {
    //   content: '';
    //   border-radius: 50%;
    //   position: absolute;
    //   top: 22px;
    //   left: 22px;
    //   right: 22px;
    //   bottom: 22px;
    //   background: #F6F6F6;
    //   transition: background .4s linear;
    //   z-index: -1;
    // }
  }
  .item-title {
    margin-bottom: 16px;
    @include text-large;
    font-weight: bold;
  }
  .item-desc {
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 24px;
    @include text-small;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }
}
.section-pos-market {
  .section-content-wrap {
    & > h2 {
      margin-bottom: 32px;
    }
  }
  .row {
    margin-bottom: -24px;
    justify-content: center;
    & + h2 {
      padding-top: 32px;
      // @include breakpoint-down(md) {
      //   padding-top: 32px;
      // }
    }
  }
  .section-head {
    margin-bottom: 24px;
    text-align: center;
  }
}
