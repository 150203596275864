.section-tabs {
  position: relative;
  color: #fff;
  height: 800px;
  padding: 48px 0;
  @include breakpoint-down(md) {
    padding: 24px 0;
    min-height: auto;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
    &::before {
      content: '';
      @include stretch;
      background: rgba(0, 0, 0, .5);
      z-index: 1;
    }
  }
  .nav-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 89px;
    list-style: none;
    padding-left: 0;
    @include breakpoint-down(md) {
      margin-bottom: 42px;
    }
    li {
      font-family: 'Poppins';
      font-weight: 700;
      @include title-4;
      white-space: nowrap;
      @include breakpoint-down(md) {
        margin-bottom: 16px;
      }
      &::before {
        display: none;
      }
      &:not(:last-child) {
        margin-right: 112px;
        @include breakpoint-down(md) {
          margin-right: 16px;
        }
      }
    }
    a {
      border-bottom: 1px solid transparent;
      color: inherit;
    }
    .active {
      a {
        color: inherit;
        border-color: inherit;
      }
    }
  }
  .item-title {
    @include title-3;
    font-family: "Poppins";
    font-weight: 600;
    margin-bottom: 16px;
    color: $brand-secondary;
  }
  .item-desc {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 32px;
    max-width: 476px;
  }
}
