.section-carousel-column {
  .swiper-slide {
    height: auto;
  }
  .section-content {
    position: relative;
  }
  .row {
    margin-bottom: -48px;
  }
  .col-lg-4 {
    margin-bottom: 48px;
  }
  .section-carousel-controls {
    display: none;
    @include breakpoint-down(md) {
      display: flex;
    }
  }
  .item-carousel {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
    text-align: center;
    max-width: 275px;
    margin: 0 auto;
    @include breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .item-media {
    position: relative;
    width: 276px;
    height: 276px;
    margin: 0 auto 24px;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
    border-radius: 50%;
  }
  .item-title {
    font-family: 'Poppins';
    @include title-4;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .item-desc {
    display: flex;
    flex: 1 0 auto;
    p {
      width: 100%;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      width: 100%;
    }
    li {
      &::before {
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 2px;
      }
    }
  }
  .item-footer {
    padding-top: 30px;
  }
}
