.promo-block {
  position: relative;
  background-color: $brand-primary;
  margin-bottom: 60px;
  padding-bottom: ratio(455px, 1420px);
  @include breakpoint-down(md) {
    padding-bottom: ratio(400px, 375px);
    height: 100%;
    // min-height: 400px;
    margin-bottom: 48px;
  }
  .container {
    @include stretch;
  }
  &-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 29px;
    color: #ffffff;
    @include stretch;
    left: 12px;
    right: 12px;
    z-index: 2;
    @include breakpoint-down(md) {
      height: 100%;
      // min-height: 344px;
      padding-bottom: 16px;
      padding-top: 73px;
    }
  }
  .desc {
    padding-top: 28px;
    margin-bottom: 32px;
    @include breakpoint-down(md) {
      display: none;
    }
    a {
      color: $yellow;
      font-weight: 700;
    }
  }
  .img {
    @include stretch;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
  .title {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-family: 'Poppins';
    p {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      @include breakpoint-down(md) {
        font-size: 24px;
        line-height: 36px;
      }
      strong {
        display: inline-block;
        padding: 10px 16px;
        background: rgba($color: #000000, $alpha: .5);
      }
      & + p {
        strong {
          padding-top: 0;
        }
      }
    }
  }
  .promo-link {
    margin-bottom: 50px;
    @include breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  &-secondary {
    background-color: $brand-secondary;
    .promo-block-wrap {
      color: #000;
      padding-bottom: 102px;
      @include breakpoint-down(md) {
        padding-bottom: 29px;
      }
    }
    .title {
      @include title-3;
    }
  }
}
