.section-history {
  .history-list {
    @include create-row();
    position: relative;
    padding-top: 24px;
    @extend .icon-chevron-left;
    &::before {
      font-family: 'iconfont';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%,0%) rotate(90deg);
      color: $brand-primary;
      z-index: 2;
    }
    &::after {
      content: '';
      position: absolute;
      height: 26px;
      left: 50%;
      top: 10px;
      width: 2px;
      transform: translate(-50%,0%);
      background: $brand-primary;
    }
  }
  .item-list {
    position: relative;
    @include col-custom(6);
    &-wrap {
      padding-bottom: 24px;
      min-height: 104px;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: $brand-secondary;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        top: 12px;
        transform: translateY(25%);
      }
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top: 12px;
      }
    }
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background: $brand-primary;
        top: 12px;
      }
    }
    &:nth-child(even) {
      @include offset-custom(6);
      text-align: left;
      &::after {
        left: -1px;
      }
      .item-list-wrap {
        padding-left: 17px;
        &::before {
         left: -4px;
        }
        &::after {
          left: -6px;
        }
      }
    }
    &:nth-child(odd) {
      text-align: right;
      .item-desc {
        margin-left: auto;
      }
     &::after {
      right: -1px;
     }
     .item-list-wrap {
      padding-right: 17px;
       &::before {
        right: -4px;
       }
       &::after {
        right: -6px;
       }
     }
    }
  }
  .item-date {
    color: $brand-primary;
    font-family: "Poppins";
    @include title-4;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .item-desc {
    @include text-small;
    max-width: 458px;
  }
}
