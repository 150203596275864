.section-step {
  text-align: center;
  .item-list {
    margin-bottom: 24px;
  }
  .item-num {
    border: 2px solid $brand-primary;
    color: $brand-primary;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include title-3;
    width: 72px;
    height: 72px;
    margin-bottom: 24px;
  }
  .item-title {
    font-weight: bold;
    margin-bottom: 16px;
    @include breakpoint-down(md) {
      font-size: 20px;
    }
    span {
      text-decoration: underline;
    }
  }
  .item-desc {
    color: #999;
    @include text-small;
    span {
      text-decoration: underline;
    }
  }
  .section-content {
    margin-bottom: 24px;
  }
  .section-footer {
    &-desc {
      margin-bottom: 24px;
    }
  }
}
.section-only-step {
  .only-step-list {
    display: flex;
    justify-content: center;
    margin: 0 -12px -24px;
    @include breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }
  .item-head {
    padding: 0 24px;
    margin-bottom: 40px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .item-media {
    position: relative;
    padding-bottom: ratio(336px, 569px);
    // margin: 0 auto 40px;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
  }
  .item-list {
    margin-bottom: 24px;
    padding: 0 12px;
    max-width: 100%;
    width: 100%;
  }
  .item-title {
    @include text-large;
    margin-bottom: 16px;
    font-weight: bold;
  }
  .item-desc {
    @include text-small;
  }
  .item-link {
    margin-top: 40px;
    @include breakpoint-down(md) {
      margin-top: 16px;
    }
  }
}
